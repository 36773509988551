import React from 'react'
import { routesAdminObj } from '../../../../routes/routes'
import {
  DesktopOutlined,
  HomeOutlined,
  SolutionOutlined,
  TabletOutlined,
  UserOutlined,
  UserSwitchOutlined,
  MenuOutlined,
  SettingOutlined
} from '@ant-design/icons'
import { useAppSelector } from '../../../../hooks'
import { role } from '../../../../constants/role'
import { useTranslation } from 'react-i18next'

export const SidebarMenu = () => {
  const { t } = useTranslation()
  const auth = useAppSelector((state) => state.auth)
  const userRole = auth?.userInfo?.role
  const dashboard = {
    key: routesAdminObj?.dashboard.path,
    label: routesAdminObj?.dashboard.title,
    icon: <HomeOutlined />
  }
  const Personal = {
    key: 'Personal',
    label: t('pageTitle.personal.title'),
    icon: <UserSwitchOutlined />,
    children: [{
      key: routesAdminObj?.myDevices.path,
      label: routesAdminObj?.myDevices.title,
      icon: <TabletOutlined />
    }
      // {
      //   key: routesAdminObj?.requests.path,
      //   label: `My ${routesAdminObj?.requests.title}`,
      //   icon: <SolutionOutlined />
      // }
    ]
  }
  const PersonalForUser = {
    key: 'Personal',
    label: t('pageTitle.personal.title'),
    icon: <UserSwitchOutlined />,
    children: [{
      key: routesAdminObj?.myDevices.path,
      label: routesAdminObj?.myDevices.title,
      icon: <TabletOutlined />
    },
    {
      key: routesAdminObj?.requests.path,
      label: `My ${routesAdminObj?.requests.title}`,
      icon: <SolutionOutlined />
    }]
  }
  const PersonalForManagerAndOperator = {
    label: t('pageTitle.personal.title'),
    icon: <UserSwitchOutlined />,
    children: [{
      key: routesAdminObj?.myDevices.path,
      label: routesAdminObj?.myDevices.title,
      icon: <TabletOutlined />
    }]
  }
  const Management = {
    key: 'Management',
    label: t('pageTitle.management.title'),
    icon: <MenuOutlined />,
    children: [{
      key: routesAdminObj?.devices.path,
      label: routesAdminObj?.devices.title,
      icon: <DesktopOutlined />
    },
    {
      key: routesAdminObj?.requests.path,
      label: routesAdminObj?.requests.title,
      icon: <SolutionOutlined />
    },
    {
      key: routesAdminObj?.users.path,
      label: routesAdminObj?.users.title,
      icon: <UserOutlined />
    },
    {
      key: routesAdminObj?.tenants.path,
      label: routesAdminObj?.tenants.title,
      icon: <SettingOutlined />
    }]
  }
  const ManagementForManagerAndOperator = {
    label: t('pageTitle.management.title'),
    icon: <MenuOutlined />,
    children: [{
      key: routesAdminObj?.devices.path,
      label: routesAdminObj?.devices.title,
      icon: <DesktopOutlined />
    },
    {
      key: routesAdminObj?.requests.path,
      label: routesAdminObj?.requests.title,
      icon: <SolutionOutlined />
    }]
  }
  let menu = []
  switch (userRole) {
    case role.user.value:
      menu = [dashboard, PersonalForUser]
      break
    case role.manager.value:
      menu = [dashboard, PersonalForManagerAndOperator, ManagementForManagerAndOperator]
      break
    case role.operator.value:
      menu = [dashboard, PersonalForManagerAndOperator, ManagementForManagerAndOperator]
      break
    case role.admin.value:
      menu = [dashboard, Personal, Management]
      break
    default:
      break
  }
  return menu
}
