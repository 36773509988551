import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import moment from 'moment'
import { useEffect, useState } from 'react'
import SelectSearchData from '../../../../components/SelectSearchData'
import { configuration } from '../../../../configuration'
import {
  COMMON_PRIORITIES,
  COMMON_PRIORITY_OBJECT,
  COMMON_STATUSES,
  COMMON_STATUS_OBJECT,
  COMMON_TRACKERS,
  COMMON_TRACKER_OBJECT
} from '../../../../constants/common'
import { role } from '../../../../constants/role'
import { useAppSelector } from '../../../../hooks'
import { RequestServices } from '../../../../services/RequestServices'
import { RequestPostType } from '../../../../types/requestType'
import { UserType } from '../../../../types/userType'
import { utils } from '../../../../utils/utils'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { DeviceServices } from '../../../../services/DeviceServices'
import { statusRequestI18n } from '../../../../constants/request'
import { useTranslation } from 'react-i18next'

const dateFormat = 'YYYY/MM/DD'
const FormItem = (props: any) => {
  const { t } = useTranslation()
  const { dataDevice = {}, forceRefeshTable: forceRefreshTable, dataRequest = {}, isDevice = false } = props
  const [form] = Form.useForm()
  const [options, setOptions] = useState<DefaultOptionType[]>([])
  const [deviceOptions, setDeviceOptions] = useState<DefaultOptionType[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [editorData, setEditorData] = useState<string>('')
  const [tracker, setTracker] = useState<string>('')
  const auth = useAppSelector((state) => state.auth)

  const isCreateNew = Boolean(!Object.keys(dataRequest).length)

  const fetchUser = async (value: string) => {
    try {
      const res = await DeviceServices.getListUsersForSelect({
        page: 1,
        limit: configuration.pageSizeSearch,
        keywords: value
      })
      const options = res.users.map((user: UserType) => ({
        value: user.id.toString(),
        label: user.full_name
      }))

      options.sort((a, b) => a.label.localeCompare(b.label))
      setOptions(options)
    } catch (error) {
      utils.handleError(error)
    }
  }

  const fetchDevice = async (value: string) => {
    try {
      const res = await DeviceServices.getListDevicesName({
        name: value
      })
      const formatterData = (res?.devices || []).map((item) => ({
        value: item.id.toString(),
        label: item.name
      }))
      setDeviceOptions(formatterData)
    } catch (error) { utils.handleError(error) }
  }

  const formatValue = (value: RequestPostType) => {
    return {
      ...value,
      description: editorData,
      assignee_id: Number(value.assignee_id),
      device_id: value.device_id || null,
      start_date: moment(value.start_date).format(dateFormat),
      due_date: value.due_date ? moment(value.due_date).format(dateFormat) : null
    }
  }

  const handleFinish = async (value: any) => {
    try {
      setIsSubmitting(true)
      const formatterValue = formatValue(value)
      if (isCreateNew) {
        await RequestServices.createNewRequest(formatterValue)
        utils.handleSuccessNotify(t('message.requestForm.createSuccess'))
      } else {
        await RequestServices.updateRequest(dataRequest.id, formatterValue)
        utils.handleSuccessNotify(t('message.requestForm.updateSuccess'))
      }
      forceRefreshTable && forceRefreshTable()
    } catch (e) {
      utils.handleError(e)
    } finally {
      setIsSubmitting(false)
    }
  }

  const checkDueDate = async () => {
    const startDate = form.getFieldValue('start_date')
    const dueDate = form.getFieldValue('due_date')

    if (!dueDate) {
      return await Promise.resolve()
    } else if (!startDate || new Date(moment(startDate).format(dateFormat)) > new Date(moment(dueDate).format(dateFormat))) {
      return await Promise.reject(
        t('message.requestForm.checkDueDate')
      )
    }

    form.setFields([
      { name: 'start_date', errors: [''], validating: true },
      { name: 'due_date', errors: [''], validating: true }
    ])
    return await Promise.resolve()
  }

  const hideBuy = ['borrow', 'return', 'warranty_claim']
  const hideBuyTracker = COMMON_TRACKERS.filter(tracker => hideBuy.includes(tracker.value))
  const trackers = isDevice
    ? hideBuyTracker
    : COMMON_TRACKERS

  useEffect(() => {
    if (Object.keys(dataRequest).length) {
      form.setFieldsValue({
        title: dataRequest.title,
        tracker: dataRequest.tracker,
        status: dataRequest.status,
        priority: dataRequest.priority,
        assignee_id: dataRequest?.assignee_id?.toString(),
        device_id: dataRequest.device_id?.toString(),
        description: dataRequest.description,
        decision: dataRequest.decision,
        manager_notes: dataRequest.manager_notes,
        start_date: moment(dataRequest.start_date).isValid()
          ? moment(dataRequest.start_date)
          : undefined,
        due_date: moment(dataRequest.due_date).isValid()
          ? moment(dataRequest.due_date)
          : undefined
      })
    } else if (Object.keys(dataDevice).length) {
      form.setFieldsValue({
        device_id: dataDevice.id?.toString()
      })
    }
  }, [JSON.stringify(dataRequest), JSON.stringify(dataDevice)])

  useEffect(() => {
    if (dataRequest.assignee_name) {
      fetchUser(dataRequest.assignee_name)
    }
    if (dataRequest.device_name) {
      fetchDevice(dataRequest.device_name)
    } else if (dataDevice.name) {
      fetchDevice(dataDevice.name)
    }
    setEditorData(dataRequest.description || '')

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataRequest), JSON.stringify(dataDevice)])

  useEffect(() => {
    fetchUser('')
    fetchDevice('')
  }, [])

  ClassicEditor.defaultConfig = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        // 'insertTable',
        '|',
        // 'imageUpload',
        '|',
        // 'blockQuote',
        '|',
        'undo',
        'redo'
      ]
    },
    image: {
      toolbar: [
        'imageStyle:full',
        'imageStyle:side',
        '|',
        'imageTextAlternative'
      ]
    }
    // table: {
    //   contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
    // },
    // language: 'en'
  }

  const isDisabled = (dataRequest.status === 'closed' ||
    dataRequest.status === 'rejected') && !isCreateNew

  const optionsStatus = () => {
    let options = COMMON_STATUSES
    switch (auth.userInfo.role) {
      case 'admin':
        options = COMMON_STATUSES.filter(item => ['open', 'in_progress', 'approved_by_admin', 'preparing', 'rejected', 'closed'].includes(item.value))
        break
      case 'manager':
        options = COMMON_STATUSES.filter(item => ['open', 'in_progress', 'approved_by_manager', 'preparing', 'rejected', 'closed'].includes(item.value))
        break
      case 'operator':
        options = COMMON_STATUSES.filter(item => ['open', 'in_progress', 'preparing', 'rejected', 'closed'].includes(item.value))
        break
      case 'user':
        options = COMMON_STATUSES.filter(item => ['open', 'closed'].includes(item.value))
        break
      default:
        break
    }

    if (!(options.some((item: { value: any }) => item.value === dataRequest.status))) {
      options.unshift({ value: dataRequest.status, label: statusRequestI18n(dataRequest.status) })
    }

    switch (dataRequest.status) {
      case 'open':
        options = options.filter(item => ['open', 'in_progress', 'preparing', 'rejected', 'closed'].includes(item.value))
        break
      case 'in_progress':
        options = options.filter(item => ['in_progress', 'approved_by_manager', 'approved_by_admin',
          'preparing', 'rejected', 'closed'].includes(item.value))
        break
      case 'approved_by_manager':
        options = options.filter(item => ['approved_by_manager', 'approved_by_admin',
          'preparing', 'rejected', 'closed'].includes(item.value))
        break
      case 'approved_by_admin':
        options = options.filter(item => ['approved_by_admin', 'preparing', 'closed'].includes(item.value))
        break
      case 'preparing':
        options = options.filter(item => ['preparing', 'closed'].includes(item.value))
        break
      default:
        break
    }
    return options
  }

  return (
    <Form
      scrollToFirstError
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      initialValues={{
        tracker: COMMON_TRACKER_OBJECT.BORROW.value,
        status: COMMON_STATUS_OBJECT.OPEN.value,
        priority: COMMON_PRIORITY_OBJECT.NORMAL.value
        // start_date: moment(),
      }}
    >
      <Row gutter={20}>
        <Col span={16}>
          <Form.Item
            label={t('pageTitle.personal.modalRequest.titleLabel')}
            name="title"
            required
            rules={[{ required: true, message: t('message.requiredRule') }]}
          >
            <Input maxLength={250} disabled={isDisabled} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t('pageTitle.personal.modalRequest.device')} name="device_id">
            <SelectSearchData options={deviceOptions} fetchData={fetchDevice} disabled={isDisabled || tracker === 'buy'} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t('pageTitle.personal.table.assignee')}
            name="assignee_id"
            rules={[{ required: true, message: t('message.requiredRule') }]}
          >
            <SelectSearchData options={options} fetchData={fetchUser} disabled={isDisabled} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t('pageTitle.personal.table.status')} name="status">
            <Select disabled={isCreateNew || isDisabled} getPopupContainer={trigger => trigger.parentNode}>
              {optionsStatus()?.map((item: any, index: number) => (
                <Select.Option value={item.value} key={index}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t('pageTitle.personal.modalRequest.tracker')} name="tracker">
            <Select
              disabled={isDisabled}
              onChange={(value) => setTracker(value)}
              getPopupContainer={trigger => trigger.parentNode}
            >
              {trackers.map((item: any, index: number) => {
                if (![COMMON_TRACKER_OBJECT.BORROW.value,
                  COMMON_TRACKER_OBJECT.RETURN.value,
                  COMMON_TRACKER_OBJECT.WARRANTY_CLAIM.value].includes(item.value)) {
                  return (
                    <Select.Option
                      disabled={role.user.value === auth.userInfo.role}
                      value={item.value}
                      key={index}
                    >
                      {item.label}
                    </Select.Option>
                  )
                }
                if (item.value === COMMON_TRACKER_OBJECT.BUY.value) {
                  return (
                    <Select.Option
                      disabled={role.manager.value === auth.userInfo.role}
                      value={item.value}
                      key={index}
                    >
                      {item.label}
                    </Select.Option>
                  )
                }
                return (
                  <Select.Option value={item.value} key={index}>
                    {item.label}
                  </Select.Option >
                )
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label={t('pageTitle.personal.modalRequest.priority')} name="priority" >
            <Select disabled={isDisabled} getPopupContainer={trigger => trigger.parentNode}>
              {COMMON_PRIORITIES.map((item: any, index: number) => (
                <Select.Option value={item.value} key={index}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t('pageTitle.personal.modalRequest.startDate')}
            name="start_date"
            rules={[
              { required: true, message: t('message.requiredRule') },
              { validator: checkDueDate }
            ]}
          >
            <DatePicker
              placeholder={t('datepickerPlaceholder')}
              format={dateFormat}
              className="w-full"
              disabled={isDisabled}
              getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={t('pageTitle.personal.modalRequest.dueDate')}
            name="due_date"
            rules={[{ validator: checkDueDate }]}
          >
            <DatePicker
              placeholder={t('datepickerPlaceholder')}
              format={dateFormat}
              className="w-full"
              disabled={isDisabled}
              getPopupContainer={trigger => trigger.parentNode as HTMLElement}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label={t('pageTitle.personal.table.description')} name="description" rules={[{ required: true, message: t('message.requiredRule') }]}>
            <CKEditor
              editor={ClassicEditor}
              data={editorData}
              onChange={(_: any, editor: any) => {
                setEditorData(editor.getData())
              }}
              disabled={isDisabled} />
          </Form.Item>
        </Col>
        <Col span={24}>
          {!isCreateNew && auth.userInfo.role !== role.user.value && (
            <Form.Item label={t('pageTitle.personal.modalRequest.decision')} name="decision">
              <Input.TextArea autoSize={{ minRows: 5, maxRows: 5 }} disabled={isDisabled} />
            </Form.Item>
          )}
        </Col>
        <Col span={24}>
          {auth.userInfo.role !== role.user.value && (
            <Form.Item label={t('pageTitle.personal.table.managerNotes')} name="manager_notes">
              <Input.TextArea autoSize={{ minRows: 5, maxRows: 5 }} disabled={isDisabled} />
            </Form.Item>
          )}
        </Col>
      </Row>
      <Button
        type="primary"
        htmlType="submit"
        loading={isSubmitting}
        className="block ml-auto"
      >
        {isCreateNew ? t('message.history.create') : t('message.history.update')}
      </Button>
    </Form>
  )
}

export default FormItem
