interface Filter {
  users: string
  requests: string
  devices: string
  historyDevices: string
  tenants: string

}
export const filterScreen: Filter = {
  users: 'users',
  requests: 'requests',
  devices: 'devices',
  historyDevices: 'historyDevices',
  tenants: 'tenants'
}
