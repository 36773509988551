import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  CheckOutlined
} from '@ant-design/icons'
import { Spin } from 'antd'
import type { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface'
import { useEffect, useState } from 'react'
import BaseTable from '../../components/BaseTable'
import { RequestServices } from '../../services/RequestServices'
import BaseModal from '../../components/BaseModal'
import CloseRequestForm from './components/CloseRequestForm'
import DeleteForm from './components/DeleteForm'
import FormItem from './components/Form'
import { useHistory } from 'react-router-dom'
import { routesObj } from '../../routes/routes'
import { configuration } from '../../configuration'
import { utils } from '../../utils/utils'
import { filterScreen } from '../../constants/filterScreen'
import {
  trackerI18n,
  priorityI18n,
  statusRequestI18n
} from '../../constants/request'
import { useAppSelector } from '../../hooks'
import { role } from '../../constants/role'
import { TableParamsType } from '../../types/tableParamsType'
import { RequestType } from '../../types/requestType'
import { useRouter } from '../../hooks/useRouter'
import { useTranslation } from 'react-i18next'

function Request () {
  const { t } = useTranslation()
  const history = useHistory()
  const router: any = useRouter()
  const requestFilter = useAppSelector((state) => state.requestFilter)
  const [dataSource, setDataSource] = useState<any>([])
  const queryParams = new URLSearchParams(window.location.search)
  const pageCurrent = Number(queryParams.get('page') || 1)
  const textSearch = queryParams.get('keywords') || ''

  const [currentPage, setCurrentPage] = useState(pageCurrent)
  const [totalItems, setTotalItems] = useState(0)
  const [dataRecord, setDataRecord] = useState({})
  const [isNewFormModalVisible, setIsNewFormModalVisible] = useState<boolean>()
  const [isDeleteModalVisible, setDeleteModalVisible] = useState<boolean>()
  const [isCloseModalVisible, setCloseModalVisible] = useState<boolean>()
  const auth = useAppSelector((state) => state.auth)
  const [loading, setLoading] = useState(true)
  const [tableParams, setTableParams] = useState<TableParamsType>({
    sortField: 'id',
    pagination: {
      current: 1
    }
  })

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<RequestType>
  ) => {
    setTableParams({
      sortField: 'id',
      pagination: {
        current: 1
      },
      filters,
      ...sorter
    })
  }

  /* eslint-disable @typescript-eslint/dot-notation */
  const getData = async (page: number, value: any, searchParams: any) => {
    try {
      const fieldParams = tableParams['order'] !== undefined ? tableParams['columnKey'] : tableParams['sortField']
      const orderParams = tableParams['order'] === 'ascend' ? 'asc' : 'desc'
      const sortParams = `${fieldParams} ${orderParams}`

      const res =
        (await RequestServices.getRequests(page, value, sortParams, searchParams)).data ||
        []
      setDataSource(res.requests)
      setTotalItems(res.meta.total_result)
    } catch (error) {
      utils.handleError(error)
    } finally {
      setLoading(false)
    }
  }
  /* eslint-disable @typescript-eslint/dot-notation */

  const handleAddNewDevice = () => {
    setDataRecord({})
    setIsNewFormModalVisible(true)
  }

  const searchParams = {
    tracker: requestFilter.tracker,
    status: requestFilter.status,
    priority: requestFilter.priority,
    start_start_date: requestFilter.start_date[0],
    end_start_date: requestFilter.start_date[1],
    start_due_date: requestFilter.due_date[0],
    end_due_date: requestFilter.due_date[1]
  }

  const getRequest = async (id: string) => {
    try {
      const { data } = await RequestServices.getDetail(id)
      setDataRecord(data.request)
      return true
    } catch (error) {
      utils.handleError(error)
    }
  }

  const handleActions = async (record, type: string) => {
    const success = await getRequest(record.id)
    if (!success) return

    switch (type) {
      case 'edit':
        setIsNewFormModalVisible(true)
        break
      case 'delete':
        setDeleteModalVisible(true)
        break
      case 'close':
        setCloseModalVisible(true)
        break
      default:
        break
    }
  }
  const handleFilter = () => {
    history.push({
      pathname: history.location.pathname,
      search: `?${new URLSearchParams({ ...router.query, page: '1' }).toString()}`
    })
  }
  useEffect(() => {
    getData(pageCurrent, queryParams.get('keywords'), searchParams)
    setCurrentPage(Number(router.query.page || 1))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    /* eslint-disable react-hooks/exhaustive-deps */
    JSON.stringify(tableParams),
    JSON.stringify(requestFilter.tracker),
    JSON.stringify(requestFilter.status),
    JSON.stringify(requestFilter.priority),
    JSON.stringify(requestFilter.start_date),
    JSON.stringify(requestFilter.due_date),
    JSON.stringify(router.query)
  ]
    /* eslint-enable react-hooks/exhaustive-deps */
  )
  useEffect(() => {
    document.title = `Devices management - ${t('pageTitle.request')}`
  }, [])

  const columns: any = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 70,
      sorter: true
    },
    {
      title: t('pageTitle.management.requests.tableRequests.title'),
      dataIndex: 'title',
      key: 'title',
      width: 250,
      sorter: true
    },
    {
      title: t('pageTitle.management.requests.tableRequests.author'),
      dataIndex: 'author_name',
      key: 'author_full_name',
      width: 190,
      sorter: true
    },
    {
      title: t('pageTitle.management.requests.tableRequests.assignee'),
      dataIndex: 'assignee_name',
      key: 'assignee_full_name',
      width: 190,
      sorter: true
    },
    {
      title: t('pageTitle.management.requests.tableRequests.tracker'),
      dataIndex: 'tracker',
      key: 'tracker',
      width: 150,
      sorter: true,
      render: (_: any, record: any) => (
        <div className="d-flex">{trackerI18n(record.tracker)}</div>
      )
    },
    {
      title: t('pageTitle.management.requests.tableRequests.deviceName'),
      dataIndex: 'device_name',
      key: 'devices_requests.name',
      width: 250,
      sorter: true
    },
    {
      title: t('pageTitle.management.requests.tableRequests.priority'),
      dataIndex: 'priority',
      key: 'priority',
      width: 120,
      sorter: true,
      render: (_: any, record: any) => (
        <div className="d-flex">{priorityI18n(record.priority)}</div>
      )
    },
    {
      title: t('pageTitle.management.requests.tableRequests.status'),
      dataIndex: 'status',
      key: 'status',
      width: 190,
      sorter: true,
      render: (_: any, record: any) => (
        <div className="d-flex">{statusRequestI18n(record.status)}</div>
      )
    },
    {
      title: t('pageTitle.management.requests.tableRequests.startDate'),
      dataIndex: 'start_date',
      key: 'start_date',
      width: 150,
      sorter: true
    },
    {
      title: t('pageTitle.management.requests.tableRequests.dueDate'),
      dataIndex: 'due_date',
      key: 'due_date',
      width: 150,
      sorter: true
    },
    {
      title: t('pageTitle.management.requests.tableRequests.actions'),
      width: 180,
      fixed: 'right',
      align: auth.userInfo.role === role.user.value ? 'center' : 'left',
      className: 'custom-action-position',
      render: (record: any) => {
        let isDisabled: boolean
        if ([role.user.value].includes(auth.userInfo.role)) {
          isDisabled = record.status !== 'open'
        } else {
          isDisabled = record.status === 'closed' || record.status === 'rejected'
        }
        return (
          <>
            <EyeOutlined
              className={'text-xl px-2 hover:text-blue-400'}
              onClick={() => {
                history.push(
                  routesObj.requestDetails.path.replace(':id', record.id),
                  { data: router.history.location.search }
                )
              }}
            />
            <EditOutlined
              className={`text-xl px-2 hover:text-blue-400 ${isDisabled && 'disable-icon'}`}
              onClick={async () => (isDisabled ? null : await handleActions(record, 'edit'))}
            />
            <DeleteOutlined
              className={`text-xl px-2 hover:text-blue-400 ${isDisabled && 'disable-icon'}`}
              onClick={async () => (isDisabled ? null : await handleActions(record, 'delete'))}
            />
            {['open', 'in_progress', 'approved_by_manager', 'approved_by_admin', 'preparing'].includes(record.status) &&
              ['borrow', 'return', 'warranty_claim'].includes(record.tracker) &&
              [role.admin.value, role.operator.value].includes(auth.userInfo.role) && (
                <CheckOutlined
                  className={'text-xl px-2 hover:text-blue-400'}
                  onClick={async () => (isDisabled ? null : await handleActions(record, 'close'))}
                />
            )}
          </>
        )
      }
    }
  ]

  if (loading) {
    return (
      <div className="flex justify-center mt-4">
        <Spin></Spin>
      </div>
    )
  }

  return (
    <div>
      <BaseTable
        columns={columns}
        dataSource={dataSource}
        callbackFilter={handleFilter}
        total={totalItems}
        pageSize={configuration.pageSize}
        handleAddNewDevice={handleAddNewDevice}
        filterScreen={filterScreen.requests}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        handleTableChange={handleTableChange}
      />
      {isNewFormModalVisible && (
        <BaseModal
          visible={isNewFormModalVisible}
          handleCancel={() => setIsNewFormModalVisible(false)}
          content={
            <FormItem
              dataRequest={dataRecord}
              isCreateNew
              forceRefeshTable={() => {
                getData(pageCurrent, textSearch, searchParams)
                setIsNewFormModalVisible(false)
              }}
            />
          }
          title={`${Object.keys(dataRecord).length ? t('pageTitle.management.requests.updateRequest') : t('pageTitle.management.requests.createRequest')
            } ${t('pageTitle.management.requests.request')}`}
          width={1000}
          footer={null}
        />
      )}
      {isDeleteModalVisible && (
        <BaseModal
          visible={isDeleteModalVisible}
          handleCancel={() => setDeleteModalVisible(false)}
          content={
            <DeleteForm
              dataRecord={dataRecord}
              forceRequestList={() => {
                setDeleteModalVisible(false)
                getData(pageCurrent, textSearch, searchParams)
              }}
              handleCancelModal={() => {
                setDeleteModalVisible(false)
              }}
            />
          }
          title={t('pageTitle.management.requests.deleteRequest')}
          width={350}
          footer={null}
          bodyStyle={{ padding: '5px 24px 14px' }}
        />
      )}
      {isCloseModalVisible && (
        <BaseModal
          visible={isCloseModalVisible}
          handleCancel={() => setCloseModalVisible(false)}
          content={
            <CloseRequestForm
              dataRequest={dataRecord}
              forceRefeshTable={() => {
                setCloseModalVisible(false)
                getData(pageCurrent, textSearch, searchParams)
              }}
            />
          }
          title={t('pageTitle.management.requests.updateRelated')}
          width={800}
          footer={null}
          bodyStyle={{ padding: '5px 24px 14px' }}
        />
      )}
    </div>
  )
}

export default Request
